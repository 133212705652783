/**
 * Min height is homepage landing page height.
 */
export default function getSuggestedHeight(document) {
    const minHeight = 415;
    const { height } = document.body.getBoundingClientRect();

    if (height < minHeight) {
        return minHeight;
    }

    return height;
}
