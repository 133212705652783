import getSuggestedHeight from '@PROJECT_ROOT/assets/desktop/js/partner/getSuggestedHeight';

export const HEIGHT_CHANGED = 'HEIGHT_CHANGED';
export const URL_CHANGED = 'URL_CHANGED';
export const SCROLL_TO_TOP = 'SCROLL_TO_TOP';
export const MOBILE_OR_DESKTOP = 'MOBILE_OR_DESKTOP';
export const REQUEST_FRAME_OFFSET = 'REQUEST_FRAME_OFFSET';
export const REQUEST_PARTNER_URL = 'REQUEST_PARTNER_URL';
// Ne želimo frame parent-u objaviti pravi razlog zašto fiksiramo visinu frame-a na 1000px iz sigurnosnih razloga. Zato
// se konstanta i njena vrijednost razlikuju. Ovo će se ukloniti jednom kad payment stranicu ugradimo u našu kao iframe.
export const TRANSITIONING_TO_PAYMENT_PAGE = 'FIX_HEIGHT_TO_1000';

export function heightChanged(heightInPixels = 0) {
    const height = heightInPixels === 0 ? document.body.getBoundingClientRect().height : heightInPixels;

    return JSON.stringify({ type: HEIGHT_CHANGED, height: `${height}px` });
}

export function urlChanged(url) {
    return JSON.stringify({ type: URL_CHANGED, url });
}

export function scrollToTop() {
    return JSON.stringify({ type: SCROLL_TO_TOP });
}

export function mobileOrDesktop() {
    if (window.location.href.indexOf(ADRIATIC_PARTNER_SCHEME_HOST) !== -1) {
        return JSON.stringify({ type: MOBILE_OR_DESKTOP, isMobile: false });
    }
    return JSON.stringify({ type: MOBILE_OR_DESKTOP, isMobile: true });
}

/**
 * Šalje se parentu kada prelazimo na stranicu plaćanja (koja nije u našoj domeni).
 */
export function movingToPaymentPage() {
    return JSON.stringify({ type: TRANSITIONING_TO_PAYMENT_PAGE });
}

export function requestFrameOffset() {
    return JSON.stringify({ type: REQUEST_FRAME_OFFSET });
}

export function requestPartnerUrl() {
    return JSON.stringify({ type: 'REQUEST_PARTNER_URL' });
}

/**
 * Postoji radi podrške za legacy frame skriptu, koja ne prepoznaje heightChanged() poruku
 */
export function legacyHeightChanged(document, heightInPixels = 0) {
    return JSON.stringify({ height: heightInPixels === 0 ? getSuggestedHeight(document) : heightInPixels });
}

/**
 * Postoji radi podrške za legacy frame skriptu, koja ne prepoznaje scrollToTop() poruku
 */
export function legacyScrollToTop() {
    return JSON.stringify({ scrolling: true });
}
