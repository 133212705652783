export const FRAME_OFFSET_CHANGED = 'FRAME_OFFSET_CHANGED';
export const PARTNER_HOST_URL = 'PARTNER_HOST_URL';

export function frameOffsetChanged(offset) {
    return JSON.stringify({ type: FRAME_OFFSET_CHANGED, offset });
}

export function partnerHostUrl() {
    return JSON.stringify({ type: PARTNER_HOST_URL, url: window.location.href });
}
