import debounce from 'lodash/debounce';
import getSuggestedHeight from '@PROJECT_ROOT/assets/desktop/js/partner/getSuggestedHeight';
import {
    heightChanged,
    urlChanged,
    legacyHeightChanged,
    mobileOrDesktop,
    requestFrameOffset,
} from '@PROJECT_ROOT/assets/shared/js/frameToPartnerMessageTypes';
import { FRAME_OFFSET_CHANGED } from '@PROJECT_ROOT/assets/shared/js/partnerToFrameMessageTypes';

export default function initializePartnerMessaging() {
    unconfiguredInitializePartnerMessaging(document);
}

export function unconfiguredInitializePartnerMessaging(document) {
    window.parent.window.postMessage(urlChanged(window.location.href), '*');
    postWindowHeightChangedMessage();

    const debouncedPostWindowHeightChangedMessage = debounce(postWindowHeightChangedMessage, 200);
    window.addEventListener('resize', debouncedPostWindowHeightChangedMessage);

    window.parent.window.postMessage(mobileOrDesktop(), '*');

    function postWindowHeightChangedMessage() {
        window.parent.window.postMessage(heightChanged(getSuggestedHeight(document)), '*');
        window.parent.window.postMessage(legacyHeightChanged(document), '*');
    }

    return function destroy() {
        window.removeEventListener('resize', debouncedPostWindowHeightChangedMessage);
    };
}

/**
 * Offset frame-a je udaljenost od vrha viewporta do gornje granice frame-a.
 *
 * Udaljenost je negativna ako je frame ispod vrha viewporta, pozitivna ako je iznad.
 *
 * @param {Number} timeout nakon kojeg se promise resolva sa undefined, koristi se *samo* za brže testove
 *
 * @return {Promise} promise koji resolva sa vrijednošću offseta, resolva se s undefined ako nema frame-a
 */
export function getFrameOffset(timeout = 5000) {
    window.parent.window.postMessage(requestFrameOffset(), '*');

    return new Promise((resolve) => {
        const resolveIfFrameOffsetMessage = ({ data }) => {
            const message = getMessageDataAsObject(data);
            if (message.type === FRAME_OFFSET_CHANGED) {
                resolve(getMessageDataAsObject(data).offset);
            }
        };
        window.addEventListener('message', resolveIfFrameOffsetMessage);

        setTimeout(() => {
            window.removeEventListener('message', resolveIfFrameOffsetMessage);
            resolve();
        }, timeout);
    });
}

export function getMessageDataAsObject(messageData) {
    try {
        return JSON.parse(messageData);
    } catch (exception) {
        return {};
    }
}
