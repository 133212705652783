import calculateToastMessageDuration from '@PROJECT_ROOT/assets/responsive-frontend/js/services/ToastCalculator';
import ToastMessage from '@PROJECT_ROOT/assets/responsive-frontend/js/services/ToastMessages';
import { getMessageDataAsObject, getFrameOffset } from '@PROJECT_ROOT/assets/shared/js/partnerMessaging';
import { FRAME_OFFSET_CHANGED } from '@PROJECT_ROOT/assets/shared/js/partnerToFrameMessageTypes';
import { isFrame } from '@PROJECT_ROOT/assets/shared/js/isPartnerService';

/*! Adriatic Library */
/* global lib, Cookies */
window.lib = {};
lib.$win = $(window);
lib.cookieNotice = {
    cookiePolicyUrl: null,
    init: function init(options) {
        const self = this;
        const cookieNotice = $('.noticeCookiesUse');
        const cookieShadow = $('.cookieShadow');

        // Cookies notice EU
        if (Cookies.get('allow_cookie_use') === undefined) {
            cookieNotice.removeClass('hidden');
            cookieShadow.removeClass('hidden');
            self.initializeCookiesPolicy();
            self.cookiePolicyUrl = options.cookiePolicyUrl;
        }

        $('#cookieCloseBtn').click(() => {
            cookieNotice.remove();
            cookieShadow.remove();
            Cookies.set('allow_cookie_use', true, {
                path: '/',
                expires: 365,
            });
        });
    },
    initializeCookiesPolicy: function initializeCookiesPolicy() {
        const self = this;
        $(document).on('click', '.cookiesPolicy', (e) => {
            e.preventDefault();
            const scriptUrl = self.cookiePolicyUrl;
            window.location.href = scriptUrl;
        });
    },
};

lib.ToastManager = {
    config: {
        time: 3000,
    },
    _messageEventHandler: () => {},

    add: function add(type, message, time) {
        const self = this;
        const toastWrap = $('.toastWrap');
        let toastMessage = null;
        let delayTime = time;

        if (delayTime === undefined) {
            delayTime = calculateToastMessageDuration(message, self.config.time);
        }

        if (isFrame()) {
            const setToastWrapStyleTop = (offset) => {
                const toastWrapStyleTop = offset > 0 ? offset : 0;
                $(toastWrap).css('top', toastWrapStyleTop + 10);
            };
            getFrameOffset().then((offset) => {
                if (offset !== undefined) {
                    setToastWrapStyleTop(offset);
                }
            });

            window.removeEventListener('message', this._messageEventHandler, false);
            this._messageEventHandler = function handleMessageFromFrame({ data }) {
                const dataAsObject = getMessageDataAsObject(data);
                if (dataAsObject.type === FRAME_OFFSET_CHANGED) {
                    setToastWrapStyleTop(dataAsObject.offset);
                }
            };
            window.addEventListener('message', this._messageEventHandler, false);
        }

        $(toastWrap).append(`<div class="toastMsg">${message}</div>`);

        toastMessage = new ToastMessage(type, message, delayTime);
        if (type === 'error') {
            delayTime = 99999;
        }

        toastMessage.showMsg(500);
        toastMessage.closeToast();
        toastMessage.hideAfter(delayTime);
    },
};
// Zakomentirano jer javlja za Chrome v120 da nije podržana
// lib.browserNotice = {
//     noticeImage: null,
//     noticeText: null,
//     init(options) {
//         let browserVersion = 0;
//         let minBrowserVersion = 999;
//         const self = this;
//         const criticalWarning = document.getElementById('criticalWarning');
//         if (Cookies.get('browser_notice') === undefined) {
//             if (/Opera[\s](\d+\.\d+)/.test(navigator.userAgent)) {
//                 minBrowserVersion = 82; // Opera
//             } else if (/MSIE (\d+\.\d+);/.test(navigator.userAgent)) {
//                 minBrowserVersion = 0; // Internet explorer
//             } else if (/Edge (\d+\.\d+);/.test(navigator.userAgent)) {
//                 minBrowserVersion = 96; // Old Edge
//             } else if (/Chrome[\s](\d+\.\d+)/.test(navigator.userAgent)) {
//                 if (window.navigator.userAgent.toLowerCase().indexOf('edg') > -1) {
//                     minBrowserVersion = 96; // Edge
//                 } else if (window.navigator.userAgent.toLowerCase().indexOf('samsungbrowser') > -1) {
//                     minBrowserVersion = 16; // Samsung internet
//                 } else {
//                     minBrowserVersion = 72; // Chrome
//                 }
//             } else if (/Safari[\s](\d+\.\d+)/.test(navigator.userAgent)) {
//                 /Version[\s](\d+\.\d+)/.test(navigator.userAgent);
//                 browserVersion = Number(RegExp.$1);
//                 minBrowserVersion = 11.1; // Safari
//             } else if (/Firefox[\s](\d+\.\d+)/.test(navigator.userAgent)) {
//                 minBrowserVersion = 78; // Firefox
//             }
//             if (browserVersion === 0) {
//                 browserVersion = parseFloat(Number(RegExp.$1));
//             }
//             if (browserVersion < minBrowserVersion) {
//                 self.noticeImage = options.noticeImage;
//                 self.noticeText = options.noticeText;
//                 criticalWarning.classList.add('error');
//                 criticalWarning.innerHTML = `<div class="criticalWarning--inner"><img class="warningIcon" src="${self.noticeImage}" alt="Adriatic.hr warning" /><p>${self.noticeText}</p></div><button class="warningCloseBtn">x</button>`;
//             }
//         }
//         $('.warningCloseBtn').click(() => {
//             criticalWarning.remove();
//             Cookies.set('browser_notice', true, {
//                 path: '/',
//                 expires: 365,
//             });
//         });
//     },
// };
// $(document).ready(() => {
//     lib.browserNotice.init({
//         noticeImage: '/assets/responsive-frontend/images/global/oldBrowser.2d1aff216da.svg',
//         noticeText: 'Vaš preglednik ne podržava novitete koje smo uveli! Preporučamo da ažurirate postojeći ili koristite neki drugi.',
//     });
// });
