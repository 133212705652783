function ToastMessage(toastType) {
    const self = this;
    const toastMsg = document.querySelector('.toastMsg');
    const toastWrap = document.querySelector('.toastWrap');

    switch (toastType) {
        case 'error':
            toastMsg.className = 'toastError';
            toastMsg.innerHTML = `<span class="closeToast"></span>${toastMsg.innerHTML}`;
            break;
        case 'success':
            toastMsg.className = 'toastSuccess';
            break;
        case 'warning':
            toastMsg.className = 'toastWarning';
            break;
        case 'info':
            toastMsg.className = 'toastInfo';
            break;
        case 'info-closable':
            toastMsg.className = 'toastInfo';
            toastMsg.innerHTML = `<span class="closeToast"></span>${toastMsg.innerHTML}`;
            break;
        case 'promoCode':
            toastMsg.className = 'toastInfo';
            toastMsg.id = 'promoCodeToast';
            toastMsg.innerHTML = `<span class="closeToast"></span>${toastMsg.innerHTML}`;
            break;
        default:
            toastMsg.className = 'toastInfo';
    }

    self.showMsg = (time) => {
        setTimeout(() => {
            toastMsg.style.display = 'block';
        }, time);
    };

    self.hideAfter = (time) => {
        setTimeout(() => {
            toastMsg.style.display = 'none';
            toastWrap.removeChild(toastMsg);
        }, time);
    };

    self.closeToast = () => {
        toastWrap.addEventListener('click', (event) => {
            if (event.target && event.target.classList.contains('closeToast')) {
                event.target.parentElement.remove();
            }
        });
    };
}

export default ToastMessage;
