/* global local_vac_html */

export function isFrame() {
    return window.location.href.indexOf(ADRIATIC_PARTNER_RESPONSIVE_SCHEME_HOST) === 0
    || window.location.href.indexOf(ADRIATIC_PARTNER_SCHEME_HOST) === 0
    || window.location.href.indexOf(ADRIATIC_PARTNER_MOBILE_SCHEME_HOST) === 0;
}

export function isHtml() {
    return typeof local_vac_html !== 'undefined' ? local_vac_html : false;
}
